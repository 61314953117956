<template>
  <div id="portal">
    <vs-progress class="w-full" v-if="!loaded" indeterminate color="primary"></vs-progress>
    <div v-else>
      <!-- portal -->
      <div class="vx-row w-full">
        <!-- meta -->
        <div class="vx-col w-2/5">
          <!-- portal_slug -->
          <vs-input :label="$t('portal_slug')" 
            v-model="portalEdited.info.portal_slug" 
            @blur="testPortalSlugAvailabilty(portalEdited.info.portal_slug)" 
            @keydown.enter="testPortalSlugAvailabilty(portalEdited.info.portal_slug)" 
            v-validate="{ required: true, regex: '^[a-z0-9]+(?:-[a-z0-9]+)*$' }" 
            :color="validateForm ? 'success' : 'danger'"/>
          <!-- portal_nicename -->
          <vs-input :label="$t('portal_nicename')" v-model="portalEdited.info.portal_nicename " />
          <!-- beta_tester -->
          <div class="flex py-2"><vs-switch v-model="portalEdited.info.beta_tester" class="mr-4"/><label>{{$t('beta_tester')}}</label></div>
          <div class="flex py-2"><vs-switch v-model="portalEdited.info.projects_location" class="mr-4"/><label>{{$t('projects_location')}}</label></div>
          <div class="flex py-2"><vs-switch v-model="portalEdited.info.projects_legend" class="mr-4"/><label>{{$t('projects_legend')}}</label></div>
          <div class="flex py-2"><vs-switch v-model="portalEdited.info.projects_coordinates_system" class="mr-4"/><label>{{$t('projects_coordinates_system')}}</label></div>
        </div>
        <!-- subscription -->
        <div class="vx-col w-2/5">
          <vs-input :label="$t('start_access')" v-model="portalEdited.subscription.start_access" required v-validate="'date_format:yyyy-MM-dd'" :color="validateForm ? 'success' : 'danger'"/>
          <vs-input :label="$t('end_access')" v-model="portalEdited.subscription.end_access" required v-validate="'date_format:yyyy-MM-dd'" :color="validateForm ? 'success' : 'danger'"/>
          <vs-input :label="$t('projects_total')" v-model="portalEdited.subscription.projects_total" required v-validate="'decimal:0'" :color="validateForm ? 'success' : 'danger'"/>
          <vs-input :label="$t('layers_total')" v-model="portalEdited.subscription.layers_total" required v-validate="'decimal:0'" :color="validateForm ? 'success' : 'danger'"/>
          <vs-input :label="$t('users_total')" v-model="portalEdited.subscription.users_total" required v-validate="'decimal:0'" :color="validateForm ? 'success' : 'danger'"/>
          <vs-input :label="$t('space_total')" v-model="portalEdited.subscription.space_total" required v-validate="'decimal:0'" :color="validateForm ? 'success' : 'danger'"/>
        </div>
        <!-- actions -->
        <div class="vx-col w-1/5">
          <vs-button class="m-6 w-full" @click="dispatchUpdates" :disabled="!displayValidationBtn">{{this.$t('Save')}}</vs-button>
          <!-- <br>displayValidationBtn: {{ displayValidationBtn }}
          <br>validateForm: {{ validateForm }}
          <br>validDiffs: {{ validDiffs }}
          <p>{{ $t('modifications') }}</p>
          <pre>
            {{ diffs }}
          </pre>
          <p>{{ $t('diffsToApiQuery') }}</p>
          <pre>
            {{ diffsToApiQuery }}
          </pre> -->
        </div>
      </div>
      <!-- users -->
      <div class="vx-col w-full">
        <h3>{{$t('Users')}}</h3>
        <vs-table :data="portalEdited.usersDetails" pagination :max-items="itemsPerPage" search>
          <template slot="thead">
            <!-- <vs-th></vs-th> -->
            <vs-th sort-key="user_id">{{ $t('userId') }}</vs-th>
            <vs-th>{{ $t('avatar') }}</vs-th>
            <vs-th sort-key="first_name">{{ $t('userFirstName') }}</vs-th>
            <vs-th sort-key="last_name">{{ $t('userLastName') }}</vs-th>
            <vs-th sort-key="user_email">{{ $t('userEmail') }}</vs-th>
            <vs-th sort-key="can_portal_access">{{ $t('can_portal_access') }}</vs-th>
            <vs-th sort-key="can_portal_access_start_date">{{ $t('can_portal_access_start_date') }}</vs-th>
            <vs-th sort-key="can_portal_access_end_date">{{ $t('can_portal_access_end_date') }}</vs-th>
            <vs-th sort-key="can_portal_manage">{{ $t('can_portal_manage') }}</vs-th>
            <vs-th sort-key="can_portal_customize">{{ $t('can_portal_customize') }}</vs-th>
            <vs-th sort-key="can_portal_users_manage">{{ $t('can_portal_users_manage') }}</vs-th>
            <vs-th sort-key="can_portal_projects_see">{{ $t('can_portal_projects_see') }}</vs-th>
            <vs-th sort-key="can_portal_projects_manage">{{ $t('can_portal_projects_manage') }}</vs-th>
            <vs-th sort-key="can_portal_layers_manage">{{ $t('can_portal_layers_manage') }}</vs-th>
            <vs-th sort-key="projects_count">{{ $t('projects_count') }}</vs-th>
            <vs-th>{{ $t('actions') }}</vs-th>
            <vs-th>{{ $t('deleteUser') }}</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" :state="userStatusColor(data[indextr])">
              <!-- user_id -->
              <vs-td :data="data[indextr].user_id">
                <span>#{{data[indextr].user_id}}</span>
              </vs-td>
              <!-- avatar -->
              <vs-td :data="data[indextr].user_id">
                <user-avatar 
                  :key="`user_avatar_${data[indextr].user_id}`"
                  :user="data[indextr]"
                  size="small"
                />
              </vs-td>
              <!-- first_name -->
              <vs-td :data="data[indextr].first_name">
                <span>{{data[indextr].first_name}}</span>
              </vs-td>
              <!-- last_name -->
              <vs-td :data="data[indextr].last_name">
                <span>{{data[indextr].last_name}}</span>
              </vs-td>
              <!-- user_email -->
              <vs-td :data="data[indextr].user_email">
                <span>{{data[indextr].user_email}}</span>
              </vs-td>
              <!-- can_portal_access -->
              <vs-td :data="data[indextr].can_portal_access">
                <vs-switch v-model="data[indextr].can_portal_access">
                  <template #on>
                  </template>
                  <template #off>🛇</template>
                </vs-switch>
              </vs-td>
              <!-- can_portal_access_start_date -->
              <vs-td :data="data[indextr].can_portal_access_start_date">
                <span>{{data[indextr].can_portal_access_start_date | shortDate}}</span>
                <template slot="edit">
                  <datepicker format="yyyy-MMMM-dd" inline v-model="data[indextr].can_portal_access_start_date"></datepicker>
                </template>
              </vs-td>
              <!-- can_portal_access_end_date -->
              <vs-td :data="data[indextr].can_portal_access_end_date">
                <span>{{data[indextr].can_portal_access_end_date | shortDate}}</span>
                <template slot="edit">
                  <datepicker format="yyyy-MMMM-dd" inline v-model="data[indextr].can_portal_access_end_date"></datepicker>
                </template>
              </vs-td>
              <!-- can_portal_manage -->
              <vs-td :data="data[indextr].can_portal_manage">
                <vs-switch v-model="data[indextr].can_portal_manage"/>
              </vs-td>
              <!-- can_portal_customize -->
              <vs-td :data="data[indextr].can_portal_customize">
                <vs-switch v-model="data[indextr].can_portal_customize"/>
              </vs-td>
              <!-- can_portal_users_manage -->
              <vs-td :data="data[indextr].can_portal_users_manage">
                <vs-switch v-model="data[indextr].can_portal_users_manage"/>
              </vs-td>
              <!-- can_portal_projects_see -->
              <vs-td :data="data[indextr].can_portal_projects_see">
                <vs-switch v-model="data[indextr].can_portal_projects_see"/>
              </vs-td>
              <!-- can_portal_projects_manage -->
              <vs-td :data="data[indextr].can_portal_projects_manage">
                <vs-switch v-model="data[indextr].can_portal_projects_manage"/>
              </vs-td>
              <!-- can_portal_layers_manage -->
              <vs-td :data="data[indextr].can_portal_layers_manage">
                <vs-switch v-model="data[indextr].can_portal_layers_manage"/>
              </vs-td>
              <!-- projects_count -->
              <vs-td :data="data[indextr].projects_count">
                <span>{{data[indextr].projects_count}}</span>
              </vs-td>
              <!-- actions -->
              <vs-td :data="data[indextr].layer_name">
                <a :href="`/admin/user/${data[indextr].user_email}`" target="_blank" rel="nofollow">{{ $t("edit") }}</a>
              </vs-td>
              <!-- /!\ deletion -->
              <vs-td :data="data[indextr].layer_name">
                <feather-icon icon="TrashIcon"  svgClasses="h-4 w-4 stroke-current text-danger cursor-pointer" @click.end="deleteUserFromPortal(data[indextr].user_id)"></feather-icon>
              </vs-td>
              <!-- expand -->
              <!-- <template #expand>
                <div class="con-content flex vx-row w-full">
                  <pre class="raw_data">{{data[indextr]}}</pre>la
                </div>
              </template> -->
            </vs-tr>
          </template>
        </vs-table>
      </div>
      <!-- projects -->
      <div class="vx-col w-full">
        <h3>{{$t('Projects')}}</h3>
        <vs-table :data="portalEdited.projectsInfos" pagination :max-items="itemsPerPage" search stripe>
          <template slot="thead">
            <vs-th></vs-th>
            <vs-th sort-key="project_id">{{ $t('project_id') }}</vs-th>
            <vs-th sort-key="project_slug">{{ $t('project_slug') }}</vs-th>
            <vs-th sort-key="project_nicename">{{ $t('project_nicename') }}</vs-th>
            <vs-th sort-key="project_coordinate_system">{{ $t('project_coordinate_system') }}</vs-th>
            <vs-th sort-key="keywords">{{ $t('keywords') }}</vs-th>
            <vs-th sort-key="project_lat">{{ $t('project_lat') }}</vs-th>
            <vs-th sort-key="project_lng">{{ $t('project_lng') }}</vs-th>
            <vs-th>{{ $t('actions') }}</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <!-- project_id -->
              <vs-td :data="data[indextr].project_id">
                <span>#{{data[indextr].project_id}}</span>
              </vs-td>
              <!-- project_slug -->
              <vs-td :data="data[indextr].project_slug">
                <span>{{data[indextr].project_slug}}</span>
                <!-- <template slot="edit">
                  <vs-input v-model="tr.project_slug" class="inputx"
                    @blur="testProjectSlugAvailabilty(tr.project_slug)" 
                    @keydown.enter="testProjectSlugAvailabilty(tr.project_slug)" 
                    v-validate="{ regex: '^[a-z0-9]+(?:-[a-z0-9]+)*$' }" 
                    :color="validateForm ? 'success' : 'danger'"/>
                </template> -->
              </vs-td>
              <!-- project_nicename -->
              <vs-td :data="data[indextr].project_nicename">
                <span>{{ data[indextr].project_nicename }}</span>
                <!-- <template slot="edit">
                  <vs-input v-model="tr.project_nicename" class="inputx"/>
                </template> -->
              </vs-td>
              <!-- project_coordinate_system -->
              <vs-td :data="data[indextr].project_coordinate_system">
                <span>{{ data[indextr].project_coordinate_system }}</span>
              </vs-td>
              <!-- keywords -->
              <vs-td :data="data[indextr].keywords">
                <span>{{ data[indextr].keywords }}</span>
              </vs-td>
              <!-- project_lat -->
              <vs-td :data="data[indextr].project_lat">
                <span>{{ data[indextr].project_lat }}</span>
              </vs-td>
              <!-- project_lng -->
              <vs-td :data="data[indextr].project_lng">
                <span>{{ data[indextr].project_lng }}</span>
              </vs-td>
              <!-- actions -->
              <vs-td :data="data[indextr].layer_name">
                <a :href="`/admin/project/${data[indextr].project_slug}`" target="_blank" rel="nofollow">{{ $t("edit") }}</a>
              </vs-td>
              <!-- expand -->
              <template #expand>
                <div class="con-content flex vx-row w-full">
                  <pre class="raw_data">{{data[indextr]}}</pre>
                </div>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </div>
      <!-- layers -->
      <div class="vx-col w-full">
        <h3>{{$t('Layers')}}</h3>
        <statistics-card-line class="vs-col w-1/2"
          hideChart
          icon="CheckIcon"
          :statistic="layersUsage.used.count"
          :statisticTitle="`${$t('usedLayers')} (${layersUsage.used.percent}%)`"
          color="primary"
        />
        <statistics-card-line class="vs-col w-1/2"
          hideChart
          icon="XIcon"
          :statistic="layersUsage.unused.count"
          :statisticTitle="`${$t('unusedLayers')} (${layersUsage.unused.percent}%)`"
          color="danger"
        />
        <vs-table :data="portalEdited.layersDetails" pagination :max-items="itemsPerPage" search stripe>
          <template slot="thead">
            <vs-th></vs-th>
            <vs-th sort-key="layer_id">{{ $t('layer_id') }}</vs-th>
            <vs-th sort-key="layer_type">{{ $t('layer_type') }}</vs-th>
            <vs-th sort-key="layer_size">{{ $t('layer_size') }}</vs-th>
            <vs-th sort-key="layer_name">{{ $t('layer_name') }}</vs-th>
            <vs-th sort-key="allocations_count">{{ $t('allocations_count') }}</vs-th>
            <vs-th>{{ $t('actions') }}</vs-th>
            <!-- <vs-th>{{ $t('layer_origin') }}</vs-th>
            <vs-th>{{ $t('layer_movable') }}</vs-th>
            <vs-th>{{ $t('layer_deformable') }}</vs-th>
            <vs-th>{{ $t('layer_key') }}</vs-th> -->
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <!-- layer_id -->
              <vs-td :data="data[indextr].layer_id">
                <span>#{{data[indextr].layer_id}}</span>
              </vs-td>
              <!-- layer_type -->
              <vs-td :data="data[indextr].layer_type">
                <span>{{ data[indextr].layer_type }}</span>
              </vs-td>
              <!-- layer_size -->
              <vs-td :data="data[indextr].layer_size">
                <span>{{ parseFloat(data[indextr].layer_size) | prettyBytes }}</span>
              </vs-td>
              <!-- layer_name -->
              <vs-td :data="data[indextr].layer_name">
                <span>{{ data[indextr].layer_name }}</span>
              </vs-td>
              <!-- allocations_count -->
              <vs-td>
                <vs-chip :color="getAllocationsColor(data[indextr].allocations_count)" class="layer_allocations_count">{{ data[indextr].allocations_count }}</vs-chip>
              </vs-td>
              <!-- actions  -->
              <vs-td :data="data[indextr].layer_name">
                <a :href="`/admin/layer/${data[indextr].layer_key}`" target="_blank" rel="nofollow">{{ $t("edit") }}</a>
              </vs-td>
              <!-- expand -->
              <template #expand>
                <div class="con-content flex vx-row w-full">
                  <pre class="raw_data">{{data[indextr]}}</pre>
                </div>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/axios.js'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import vSelect from 'vue-select'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from 'apexcharts'
import Datepicker from 'vuejs-datepicker'
import { getDiff } from 'recursive-diff'
// import logActivity from '@/logActivity.js'
import CryptoJS from 'crypto-js'
import md5 from 'crypto-js/md5'

export default {
  components: {
    moment,
    VueApexCharts, 
    vSelect, 
    Datepicker,
    StatisticsCardLine
  },
  data () {
    return {
      apexChatData,
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      appConfig: this.$appConfig,
      loaded: false,
      errorNotified: false,
      newProjectsSlugsVerified: true,
      displayValidationBtn: false,
      itemsPerPage: 10,
      projectSearched: '',
      portal_slug: '',
      portal: {},
      portalEdited: {}
    }
  },
  methods: {
    deleteUserFromPortal (user_id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.$t('deleteUserConfirmMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: () => {
          const params = new FormData()
          // add context to query
          params.set('context', JSON.stringify(this.diffsToApiQuery.context))
          // add impacted user to query
          params.set('user_id', user_id)

          const rimnat_api_call_url = `${this.api_server_baseurl}/admin/portal/deleteUserPortalCaps`
          axios.post(rimnat_api_call_url, params)
            .then((response) => { 
              if (response.data[0] === 'ok' || response.data == 'ok') {
                this.$vs.notify({
                  color:'success',
                  title:this.$t('success'),
                  text:this.$t('deletedUser')
                })
              } else {
                console.log(response)
                this.$vs.notify({
                  color:'danger',
                  title:this.$t('error'),
                  text:this.$t('notDeletedUser')
                })
              }
              this.refreshAll()
              // logActivity.add('admin-updatePortalSettings', params)
            })
            .catch((error)   => { 
              console.log(error)
              this.$vs.notify({
                color:'danger',
                title:this.$t('error'),
                text:this.$t('notDeletedUser')
              })
              this.refreshAll()
            })
        }
      })
    },
    dispatchUpdates () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.$t('dispatchPortalUpdatesText'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: () => {
          const params = new FormData()
          for (const [key, value] of Object.entries(this.diffsToApiQuery)) {
            params.set(key, JSON.stringify(value))
          }
          // params = JSON.stringify(params)
          const rimnat_api_call_url = `${this.api_server_baseurl}/admin/portal/updatePortalSettings`
          axios.post(rimnat_api_call_url, params)
            .then((response) => { 
              if (response.data[0] === 'ok' || response.data == 'ok') {
                this.$vs.notify({
                  color:'success',
                  title:this.$t('success'),
                  text:this.$t('success')
                })
              } else {
                console.log(response)
                this.$vs.notify({
                  color:'danger',
                  title:this.$t('error'),
                  text:response.data
                })
              }
              this.refreshAll()
              // logActivity.add('admin-updatePortalSettings', params)
            })
            .catch((error)   => { 
              console.log(error)
              this.$vs.notify({
                color:'danger',
                title:this.$t('error'),
                text:error
              })
              this.refreshAll()
            })
        }
      })
    },
    getAllocationsColor (num) {
      if (num === '0')  return 'danger'
      if (num === '1')  return 'primary'
      if (num > 1)  return 'success'
      return 'primary'
    },
    bytesToGb (num) {
      let exponent = null
      const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      exponent = Math.min(Math.floor(Math.log(num) / Math.log(1024)), units.length - 1)
      num = (num / Math.pow(1024, exponent)).toFixed(2) * 1
      return parseInt(num)
    },
    userAvatarImgUrl (userId) {
      return `${this.$appConfig.imgBaseUrl}/user/avatar/${userId}/thumb_150_150.jpg`
    },
    finalControl () {
      const diffsExists = this.diffs.length > 0
      const formatIsValid = this.validateForm && this.validDiffs
      const projectsSlugsOk = this.newProjectsSlugsVerified
      if (diffsExists) {
        if (formatIsValid && projectsSlugsOk) {
          if (this.errorNotified) {
            this.$vs.notify({ 
              title: this.$t('valid values'), 
              color: 'success', 
              position: 'top-center', 
              time: 3000, 
              iconPack: 'feather',
              icon:'icon-alert-check' 
            })
            this.errorNotified = false
          }
          this.displayValidationBtn = true
        } else {
          if (!this.errorNotified) {
            this.$vs.notify({ 
              title: this.$t('invalid values'), 
              color: 'danger', 
              position: 'top-center', 
              time: 3000, 
              iconPack: 'feather',
              icon:'icon-alert-triangle' 
            })
            this.errorNotified = true
          }
          this.displayValidationBtn = false
        }
      } else {
        this.displayValidationBtn = false
      }
    },
    urlReady (string) {
      const chars = /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(string)
      const size = (string.length > 3 && string.length < 30)
      return chars && size
    },
    decoded (string) {
      return decodeURIComponent(string)
    },
    refreshAll () {
      this.loaded = false
      this.getPortalsInfos()
    },
    testPortalSlugAvailabilty (portal_slug) {
      const params = `portal_slug=${portal_slug}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/portal/GetPortalSlugAvailability?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          const available = response.data
          if ((this.portalEdited.info.portal_slug != this.portal.info.portal_slug) && (!available || this.portalEdited.info.portal_slug.length < 3 || !this.validateForm)) {
            this.portalEdited.info.portal_slug = this.portal.info.portal_slug
            this.$vs.notify({ 
              title: this.$t('portalSlugNotAvailable'), 
              color: 'danger', 
              position: 'top-center', 
              time: 15000, 
              iconPack: 'feather',
              icon:'icon-alert-triangle' 
            })
          }
        })
        .catch((error)   => { console.log(error) })
    },
    testProjectSlugAvailabilty (project_slug) {
      let projectSlugChangesExists = false
      this.diffs.forEach(e => {
        if (!projectSlugChangesExists) projectSlugChangesExists = (e.path[2] == 'project_slug' && e.val != e.oldVal)
      })
      if (projectSlugChangesExists) {
        const params = `project_slug=${project_slug}`
        const rimnat_api_call_url = `${this.api_server_baseurl}/admin/portal/GetProjectSlugAvailability?${params}`
        axios.get(rimnat_api_call_url, {
        })
          .then((response) => { 
            const available = response.data
            if (!available) {
              this.$vs.notify({ 
                title: this.$t('projectSlugNotAvailable'), 
                color: 'danger', 
                position: 'top-center', 
                time: 15000, 
                iconPack: 'feather',
                icon:'icon-alert-triangle' 
              })
            }
            this.newProjectsSlugsVerified = false
          })
          .catch((error)   => { console.log(error) })
      }
    },
    getPortalsInfos () {
      const params = `portal_slug=${this.portal_slug}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/portal/getPortalSettings?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          // convert bad bool values
          const data = response.data
          data.info.projects_location = data.info.projects_location == 1
          data.info.projects_legend = data.info.projects_legend == 1
          data.info.projects_coordinates_system = data.info.projects_coordinates_system == 1
          data.info.beta_tester = data.info.beta_tester == 1
          data.usersDetails.forEach((user, index) => {
            for (const [key, value] of Object.entries(user)) {
              if (key.includes('can_') && !key.includes('_date')) {
                data.usersDetails[index][key] = value == '1'
              }
            }
          })
          data.layersDetails.forEach((user, index) => {
            for (const [key, value] of Object.entries(user)) {
              if (key.includes('able')) {
                data.layersDetails[index][key] = value == '1'
              }
            }
          })
          // convert sizes in human readable values
          data.subscription.space_total = this.bytesToGb(data.subscription.space_total)
          // decode encoded values
          const projectsDecoded = data.projectsInfos.map((p) => {
            p.project_nicename = decodeURIComponent(p.project_nicename)
            return p
          })
          data.projectsInfos = projectsDecoded
          this.portal = JSON.parse(JSON.stringify(data))
          this.portalEdited = JSON.parse(JSON.stringify(data))
          this.loaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    getValues (serie) {
      return Object.entries(serie)
        .map(item => parseFloat(item[1].result))
    },
    getLabels (serie) {
      return Object.entries(serie)
        .map(function (item) {
          let label = Object.values(item[1])[0]
          const maxKey = Object.keys(item[1]).length - 2
          for (let i = 1; i <= maxKey; i++) {
            const val = `0${Object.values(item[1])[i]}`.slice(-2)
            label = `${label}.${val}`
          }
          return label
        })
    }, 
    getSizeColor (num) {
      if (num > 80)  return 'danger'
      if (num > 60)  return 'warning'
      if (num >= 40) return 'primary'
      if (num < 40)  return 'success'
      return 'primary'
    },
    userAccessStatus (user) {
      let res = 'active'
      if (user.can_portal_access != 1) res = 'blacklisted'
      if ((user.can_portal_access_end_date && moment().isAfter(user.can_portal_access_end_date))) res = 'expired'
      if ((user.can_portal_access_start_date && moment().isBefore(user.can_portal_access_start_date))) res = 'not_yet'
      return res 
    },
    userStatusColor (user) {
      let color = ''
      const status = this.userAccessStatus(user)
      if (status == 'expired' || status == 'blacklisted') color = 'danger'
      if (status == 'not_yet') color = 'warning'
      return color
    }
  },
 
  computed: {
    layersUsage () {
      if (this.portal != null && this.portal.layersDetails.length > 0) {
        const used = this.portal.layersDetails.map(item => item.allocations_count).filter(c => c != 0).length
        const unused = this.portal.layersDetails.map(item => item.allocations_count).filter(c => c == 0).length
        return {
          used: {
            count: used,
            percent: Math.round(used / (used + unused) * 100, 0)
          },
          unused: {
            count: unused,
            percent: Math.round(unused / (used + unused) * 100, 0)
          }
        }
      }
    },
    validateForm () {
      return !this.errors.any() && this.validDiffs
    },
    diffsToApiQuery () {
      return { 
        context: {
          portal_id: this.portal.info.portal_id,
          services_user_id: this.$store.state.AppActiveUser.user_id,
          services_user_hash: this.$store.state.AppActiveUser.user_hash
        }, 
        diffs: this.diffsToApi
      }
    },
    diffsToApi () {
      const res = []
      this.diffs.forEach(e => {
        if (e.op == 'update' && e.val != e.oldVal) {
          const fullpath = e.path.join('.')
          const param = fullpath.substr(0, fullpath.lastIndexOf('.'))
          const paramLenght = param.length
          let entity = null
          let entity_id = null
          if (param.includes('usersDetails')) {
            const userIndex = param.substr(param.lastIndexOf('.') + 1)
            const userId = this.portal.usersDetails[userIndex].user_id
            entity = 'user'
            entity_id = userId
          } else if (param.includes('subscription')) {
            entity = 'subscription'
            entity_id = this.portal.info.portal_id
          } else if (param.includes('info')) {
            entity = 'portal'
            entity_id = this.portal.info.portal_id
          } else if (param.includes('projectsInfos')) {
            const projectIndex = param.substr(param.lastIndexOf('.') + 1)
            const projectId = this.portal.projectsInfos[projectIndex].project_id
            entity = 'project'
            entity_id = projectId
          }
          const prop = fullpath.substr(paramLenght + 1)
          const val = e.val
          const diff_hash = md5(`${entity}${entity_id}${prop}${val}`).toString(CryptoJS.enc.md5)
          res.push({entity, entity_id, prop, val, diff_hash})
        }
      })
      return res
    },
    diffs () {
      return getDiff(this.portal, this.portalEdited, true)
    },
    validDiffs () {
      let valid = true
      this.diffs.forEach(e => {
        if (e.op == 'update' && e.val != e.oldVal) {
          if (e.path.includes('project_slug') || e.path.includes('portal_slug')) {
            valid = this.urlReady(e.val)
          }
        }
      })
      return valid
    },
    startDate () {
      return new Date(this.start).toISOString().split('T')[0]
    },
    endDate () {
      return new Date(this.end).toISOString().split('T')[0]
    }
  },

  beforeMount () {
    this.portal_slug = this.$route.params.portal_slug
  },

  mounted () {
    document.body.classList.add('theme-dark')
    this.refreshAll()
  },

  watch: {
    newProjectsSlugsVerified () {
      this.finalControl()
    },
    diffs () {
      this.finalControl()
    },
    validDiffs () {
      this.finalControl()
    },
    validateForm () {
      this.finalControl()
    },
    start () {
      this.refreshAll()
    },
    end () {
      this.refreshAll()
    }
  }
}

</script>
<style lang="scss">

.theme-dark input {
    background: none !important;
}
#portal {
  font-size: 0.9rem;
  .vs-con-table .empty {
      color: rgba(var(--vs-dark),0.5);
  }
  .vs-table {
  }
  pre {
  }
}
</style>
